import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";

import { Link, graphql, useStaticQuery } from "gatsby";

import * as styles from "../styles/frameworks.module.css";

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "lifestyle" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            title
            imageurl
            description
            date
            author
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const Lifestyle = props => {
  const [ad, setAd] = useState({});
  useEffect(() => {
    const randomvalue = Math.floor(Math.random() * 1000);
    switch (true) {
      case randomvalue < 210:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-13608893",
          src: "https://www.lduhtrp.net/image-100376026-13608893",
          alt: "Hostinger",
        });
        break;

      case randomvalue > 210 && randomvalue < 500:
        setAd({
          href: "https://www.dpbolvw.net/click-100376026-14326263",
          src: "https://www.awltovhc.com/image-100376026-14326263",
          alt: "Popular Domains for just 99 Cents at Namecheap!",
        });
        break;

      case randomvalue > 500 && randomvalue < 700:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-14483390",
          src: "https://www.tqlkg.com/image-100376026-14483390",
          alt: "",
        });
        break;

      case randomvalue > 700 && randomvalue < 760:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-13868749",
          src: "https://www.ftjcfx.com/image-100376026-13868749",
          alt: "",
        });
        break;

      case randomvalue > 760 && randomvalue < 800:
        setAd({
          href: "https://www.anrdoezrs.net/click-100376026-11751862",
          src: "https://www.awltovhc.com/image-100376026-11751862",
          alt: "Get up to 30% off all new products with GoDaddy!",
        });
        break;

      case randomvalue > 800 && randomvalue < 900:
        setAd({
          href: "https://www.kqzyfj.com/click-100376026-10833186",
          src: "https://www.awltovhc.com/image-100376026-10833186",
          alt: "Get Hosting for $1.00*/mo with GoDaddy",
        });
        break;

      case randomvalue > 900:
        setAd({
          href: "https://www.kqzyfj.com/click-100376026-1452679",
          src: "https://www.lduhtrp.net/image-100376026-14526799",
          alt: "",
        });
        break;

      default:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-14483390",
          src: "https://www.tqlkg.com/image-100376026-14483390",
          alt: "",
        });
    }
  }, [props]);

  return (
    <Layout>
      <Helmet>
        <title>Webdevolution | Developer lifestyle articles</title>
        <meta name="description" content="Developer lifestyle blog posts." />
      </Helmet>

      {props.data.allMarkdownRemark.edges.length == 0 ? (
        <div>No posts in this caterory yet!</div>
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.adContainerWide}>
            {/* <AdComponent href={ad.href} src={ad.src} alt={ad.alt}></AdComponent> */}
          </div>
          {props.data.allMarkdownRemark.edges.map(i => {
            return (
              <Link
                key={i.node.frontmatter.title}
                to={`/blog/${i.node.fields.slug}`}
              >
                <div className={styles.postContainer}>
                  <img
                    className={styles.image}
                    src={i.node.frontmatter.imageurl}
                    alt=""
                  />

                  <div className={styles.postText}>
                    <h1 className={styles.posth1}>
                      {i.node.frontmatter.title}
                    </h1>

                    <p className={styles.postp}>
                      {i.node.frontmatter.description}
                    </p>
                    <p className={styles.authorSpan}>
                      {i.node.frontmatter.date} By{" "}
                      <span className={styles.authorSpan}>
                        {i.node.frontmatter.author}
                      </span>
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
      <div className={styles.adContainerSmall}>
        <a href="https://www.kqzyfj.com/click-100376026-14439996" target="_top">
          <img
            src="https://www.ftjcfx.com/image-100376026-14439996"
            width="300"
            height="250"
            alt=""
            border="0"
          />
        </a>
      </div>
    </Layout>
  );
};
export default Lifestyle;
